<template>
  <div class="flex-center">
    <div class="content full-height flex-center">
      <h1>
        Projects<br /> 
        Coming Soon
      </h1>
    </div>
  </div>
</template>

<script>
  export default {
    created () {
      this.$emit('positionDot', {'top': '30px', 'right': '120px', 'bottom': 'auto', 'left': 'auto'})
    },
    beforeRouteLeave(to, from, next) {
      this.$emit('addAnimation', ['animate-dot__up'])
      setTimeout(() => {
        next()
      }, 500);
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if(from.name != null){
          vm.$emit('addAnimation', ['animate-dot__down'])
          setTimeout(() => {
            vm.$emit('removeAnimation', ['animate-dot__up','animate-dot__down'])
          }, 1000);
        }
      })
    }
  }
</script>
